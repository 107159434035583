import React, {useState} from 'react';
import {Alert, AutoComplete, Checkbox, Col, Form, Input, InputNumber, Radio, Row, Select} from "antd";
import {currencyFormatter, currencyParser} from "../../functions/functions";
import {Geo} from "aws-amplify";
import {correctValueForDb} from "../../../data-source/providers/MortgageProvider";
import {isFuture} from "date-fns";
import countries from "../../../../../app/data/countries.json"

FormItemInput.propTypes = {

};
const {TextArea} = Input;
function Address({refToFocus,...props}) {
    const minWords = 2
    const maxWords = 5
    const [state, setState] = useState({
        options: [],
        fetching: false,
        string: ''
    })
    const onSearch = (text) => {
        setState(prev => ({...prev, string: text}))
        if (!state.fetching) {
            const noResults = !state.options.filter(address => address.value.startsWith(text)).length
            if (noResults) {
                const words = text.split(" ");
                if (words.length > minWords && words.length < maxWords) {
                    getItems(text)
                }
            }
        }

    }
    const getItems = async (v) => {
        setState(prev => ({...prev, fetching: true}))

        const results = await Geo.searchByText(v, {
            countries: ["IRL"], // Alpha-3 country codes
            maxResults: 50, // 50 is the max and the default
        })

        const opts = results.map(item => ({value: item.label, 1200: JSON.stringify(item.geometry)}))
        setState(prev => ({...prev, fetching: false, options: opts}))
    }
    return <AutoComplete
        rootClassName="font-size-16"
        {...props}
        size={"large"}
        options={state.options}
        onSearch={onSearch}
        ref={refToFocus}
    />
}
function DOBArray(props) {

    return <Form.Item
        {...props}
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
            return prevValues.countDependents !== currentValues.countDependents
        }}
    >
        {({getFieldValue}) => {
            return (
                <Form.List
                    name={props.id}
                >
                    {(fields, {add, remove}, {errors}) => {
                        return (
                            <Row key={`ages-${fields.length}`}>
                                {fields.map((field, index) => {
                                    const {key, ...restField} = field
                                    return (<Col xs={24} key={`col-${key}`}>
                                        <Form.Item
                                            key={key}
                                            {...restField}
                                            noStyle
                                            rules={[
                                                {
                                                    validator: async (_, value) => {
                                                        if (!value) {
                                                            return Promise.reject(new Error('Missing Date of Birth'));
                                                        }
                                                        let err = false
                                                        let str = correctValueForDb('ddmmyyyy', value)
                                                        if (str) {
                                                            let dte = new Date(str)
                                                            if (isFuture(dte)) {
                                                                return Promise.reject(new Error('Future Date Not Permitted'));
                                                            }
                                                            if (err) {
                                                                return Promise.reject(new Error('Invalid Date of Birth'));
                                                            }
                                                        }
                                                        return Promise.resolve()
                                                    },
                                                },
                                            ]}
                                        >
                                            <DateString style={{marginBottom: 3}} onBlur={props.onBlur} refToFocus={index===0 ? props.refToFocus : null}/>
                                        </Form.Item>

                                    </Col>)
                                })}
                            </Row>)
                    }
                    }
                </Form.List>
            )
        }}

    </Form.Item>

}
function DateString(props) {
    function formatDate(str) {
        const correctDay = (str, moreParts) => {
            if (str.length === 1) {
                if (moreParts || parseInt(str) > 3) {
                    str = '0' + str
                }
            }
            if (parseInt(str) > 31) {
                str = str.slice(0, -1)
            }
            return str
        }
        const correctMonth = (str, moreParts) => {
            if (str.length === 1) {
                if (moreParts || parseInt(str) > 1) {
                    str = '0' + str
                }
            }
            if (parseInt(str) > 12) {
                str = str.slice(0, -1)
            }
            return str
        }
        const correctYear = (str) => {
            const maxYear = ((new Date()).getFullYear() + 49).toString().slice(0, -1)
            // detect and correct YY format to YYYY
            if ([5, 6, 7, 8, 9].includes(parseInt(str))) {
                str = 190 + parseInt(str)
            }
            if ([0].includes(parseInt(str))) {
                str = 200 + parseInt(str)
            }
            str = str.toString()
            // prevent starting a year incorrectly
            if (
                (str.length === 1 && ![1, 2].includes(parseInt(str))) ||
                (str.length === 2 && ![19, 20].includes(parseInt(str))) ||
                (str.length === 3 && (parseInt(str) < 195 || parseInt(str) > maxYear))) {
                str = str.slice(0, -1)
            }
            return str
        }
        const parts = str.split('/').map(item => item.trim())
        const newParts = parts.map((part, i) => {
            if (props?.noDays) {
                if (i === 0) {
                    return correctMonth(part, parts.length > 1)
                }
                if (i === 1) {
                    return correctYear(part)
                }
            } else {
                if (i === 0) {
                    return correctDay(part, parts.length > 1)
                }
                if (i === 1) {
                    return correctMonth(part, parts.length > 2)
                }
                if (i === 2) {
                    return correctYear(part)
                }
            }
            return null
        })
        // add next part when part is completed
        if ((newParts.length === 1 && newParts[0].length === 2)) {
            newParts.push('')
        }
        if (!props?.noDays) {
            if (newParts.length === 2 && newParts[1].length === 2) {
                newParts.push('')
            }
        }
        return newParts.join(' / ')
    }
    let {noDays, refToFocus, ...other} = props
    let elementProps = {...other}
    elementProps.onKeyPress = function (e) {
        let maxLength = props?.noDays ? 9 : 14
        if (e.key === 'Backspace' || e.keyCode === 8) {
            return true
        }

        if (e.target.value.length >= maxLength) {
            e.preventDefault()
            return false
        }

        //if the cursor is at the end of the field, format to a date like string
        if (e.target.value.length === e.target.selectionStart && e.target.selectionEnd - e.target.selectionStart === 0) {
            e.preventDefault()

            const charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
            const charStr = String.fromCharCode(charCode);

            // prevent if not a digit or slash
            if (!(/\d/.test(charStr)) && charStr !== '/') {
                return false
            }

            let orig = e.target.value

            // if slash, make sure there isn't a previous one
            if (charStr === '/' && orig.trimRight().endsWith('/')) {
                return false
            }

            const temp = orig + '' + charStr

            //only allow a slash if the day or month is 1 char len
            // if (charStr === '/') {
            //     //if ([1, 7].includes(orig.length)) {
            //         formatDate(temp)
            //    // } else {
            //    //     return false
            //    // }
            // }
            e.target.value = formatDate(temp)
            props.onChange(e)
            return true
        }

    }
    elementProps.placeholder = props?.noDays ? 'MM / YYYY' : 'DD / MM / YYYY'
    return (<Input {...elementProps} inputMode="numeric" pattern="[0-9]*" ref={refToFocus}/>)
}
function Lender(props){
    const options = ['AIB','Avant Money','Bank of Ireland', 'Credit Union', 'EBS', 'Permanent TSB', 'Revolut', 'Barclays Bank Ireland' ].map(item=>({value:item}))
    return <AutoComplete
        ref={props.ref}
        rootClassName="font-size-16"
        onBlur={props.onBlur}
        {...props}
        size={"large"}
        options={options}
    />
}
function Phone({refToFocus, ...props}) {
    const prefix = ['0402', '0404', '0504', '0505', '01', '071', '074', '083', '085', '086', '087', '089', '090']
    const array = ['2', '4', '5', '6', '9']
    array.forEach(secondDigit => {
        let i = 1
        while (i < 10) {
            let newPfx = `0${secondDigit}${i}`
            if (!['092', '054', '055'].includes(newPfx)) {
                prefix.push(newPfx)
            }
            i += 1
        }
    })
    let onKeyPress = function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            return e.target.blur()
        }
        if (e.key === 'Backspace' || e.keyCode === 8) {
            return
        }

        const charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
        const charStr = String.fromCharCode(charCode);
        const digit = (/\d/.test(charStr) || ['(', ')', '-'].includes(charStr))
        if (!digit) return

        // if the cursor is at the end of the field, format to a date like string
        if (e.target.value.length === e.target.selectionStart && e.target.selectionEnd - e.target.selectionStart === 0) {
            let orig = e.target.value
            const charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
            const charStr = String.fromCharCode(charCode);
            let temp = orig + '' + charStr
            if (temp === '00') {
                e.preventDefault()
                e.target.value = "+"
                return
            }
            if (prefix.includes(temp)) {
                e.preventDefault()
                temp = `(${temp}) `
                e.target.value = temp
                return
            }

            const dashLen = temp.includes('(01)') ? 8 : 9
            const l = temp.split('')
            if (l.includes('(') && l.includes(')') && l.length === dashLen) {
                e.preventDefault()
                e.target.value = `${temp}-`
                return
            }
        }
    }
    return (<Input {...props} onKeyPress={onKeyPress} ref={refToFocus}/>)
}
function Country(props) {
    let keyName = props?.nationality ? "nationality" : "en_short_name"
    let options = countries.map(item => {
        return {
            label: item[keyName],
            value: item.alpha_2_code
        }
    })
    let {nationality, ...other} = props
    return <Select
        showSearch
        {...other}
        options={options}
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
        filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        }
    />
}
function MortgageProvider(props){
    const options = ['AIB','Haven','Avant Money','Bank of Ireland', 'ICS Mortgages', 'Finance Ireland', 'Credit Union', 'EBS', 'Permanent TSB', 'Barclays Bank Ireland' ].map(item=>({value:item}))
    return <AutoComplete
        rootClassName="font-size-16"
        {...props}
        size={"large"}
        options={options}
    />
}
const WeightInput = ({name, value, onChange, onBlur }) => {
    let defaultKilo = ''
    let defaultStone = ''
    let defaultPound = ''
    if (!isNaN(value)){
        defaultKilo = value
        const totalPounds = value * 2.20462; // default value is in kilos
        defaultStone = Math.floor(totalPounds / 14);
        defaultPound = Number( (totalPounds % 14).toFixed(1) );
    }
    const [kilos, setKilos] = useState(defaultKilo);
    const [stone, setStone] = useState(defaultStone);
    const [pounds, setPounds] = useState(defaultPound);

    const handleKilosChange = (newKilos) => {
        let k = parseInt(newKilos)
        setKilos(k);
        const totalPounds = k * 2.20462;
        const newStone = Math.floor(totalPounds / 14);
        const newPounds = totalPounds % 14;
        setStone(Number(newStone.toFixed(1)));
        setPounds(Number(newPounds.toFixed(1)));
        onChange( k);
    };

    const handleStoneChange = (newStone) => {
        let s = parseInt(newStone)
        setStone(s);
        const totalPounds = ( s * 14 ) + pounds;
        const newKilos = totalPounds / 2.20462;
        let k = Number(newKilos.toFixed(0))
        setKilos(k);
        onChange(k);
    };

    const handlePoundsChange = (newPounds) => {
        let p = Number(newPounds)
        setPounds(p);
        const totalPounds = (stone * 14 )+ p;
        const newKilos = totalPounds / 2.20462;
        let k = Number(newKilos.toFixed(0))
        setKilos(k);
        onChange(k);

    };

    return (
        <Row gutter={[8,8]}>
            <Col xs={24}>
                <InputNumber
                    value={kilos}
                    name={name}
                    onChange={handleKilosChange}
                    addonAfter="kg"
                    onBlur={onBlur}
                />
            </Col>
            <Col xs={12}>
                <InputNumber
                    value={stone}
                    onChange={handleStoneChange}
                    addonAfter="st"
                />
            </Col>
            <Col xs={12}>
                <InputNumber
                    value={pounds}
                    onChange={handlePoundsChange}
                    addonAfter="lb"
                    onBlur={onBlur}
                />
            </Col>
        </Row>
    );
};
const HeightInput = ({ name, value, onChange, onBlur }) => {
    let defaultCm = '';
    let defaultFeet = '';
    let defaultInches = '';

    if (!isNaN(value)) {
        defaultCm = value;
        const totalInches = value / 2.54; // default value is in cm
        defaultFeet = Math.floor(totalInches / 12);
        defaultInches = Number((totalInches % 12).toFixed(1));
    }

    const [cm, setCm] = useState(defaultCm);
    const [feet, setFeet] = useState(defaultFeet);
    const [inches, setInches] = useState(defaultInches);

    const handleCmChange = (newCm) => {
        let c = parseInt(newCm)
        setCm(c);
        const totalInches = c / 2.54;
        const newFeet = Math.floor(totalInches / 12);
        const newInches = totalInches % 12;
        setFeet(Number(newFeet.toFixed(1)));
        setInches(Number(newInches.toFixed(1)));
        onChange(c);
    };

    const handleFeetChange = (newFeet) => {
        let f = parseInt(newFeet)
        setFeet(f);
        const totalInches = (f * 12) + inches;
        const newCm = totalInches * 2.54;
        let c = Number(newCm.toFixed(0))
        setCm(c);
        onChange(c);
    };

    const handleInchesChange = (newInches) => {
        setInches(newInches);
        const totalInches = feet * 12 + newInches;
        const newCm = totalInches * 2.54;
        let c = Number(newCm.toFixed(0))
        setCm(c);
        onChange(c);
    };

    return (
        <Row gutter={[8, 8]}>
            <Col xs={24}>
                <InputNumber
                    value={cm}
                    name={name}
                    onChange={handleCmChange}
                    addonAfter="cm"
                    onBlur={onBlur}
                />
            </Col>
            <Col xs={12}>
                <InputNumber
                    value={feet}
                    onChange={handleFeetChange}
                    addonAfter="ft"
                />
            </Col>
            <Col xs={12}>
                <InputNumber
                    value={inches}
                    onChange={handleInchesChange}
                    addonAfter="in"
                    onBlur={onBlur}
                />
            </Col>
        </Row>
    );
};
function FormItemInput(props) {
    const form = Form.useFormInstance()
    const renderQuestion = (question, formInstance) => {
        if (question?.alert){
                return <Alert message={question.alert.message} type="info" showIcon className="flex items-start"/>
        }

        let rules = question.rules
        if (typeof question.rules === "function"){
            rules = question.rules(formInstance)
        }
        let restProps = {}



        const specialBlur = async (e)=>{
            if (e){
                try{
                    if (form){
                        let v = await form.validateFields([e.target.name])
                    }
                    if (props?.onBlur){
                        props.onBlur(e)
                    }
                }
                catch (er) {
                    console.log(er)
                }
            }


        }

        if (question.hasOwnProperty('dependencies')) {
            restProps.dependencies = question.dependencies
        }
        if (!props.open){
            return (
            <>
                <Form.Item noStyle rules={rules} name={question.name}><Input type="hidden"/></Form.Item>
                <Form.Item shouldUpdate noStyle>
                    {({ getFieldError }) => {
                        const error = getFieldError(question.name);
                        if (error.length) {
                            console.log({error})
                        }
                        return error.length > 0 ? (
                            <div style={{ color: 'red' }}>{error[0]}</div>
                        ) : null;
                    }}
                </Form.Item>
            </> )
        }
        switch (question.answer.type) {
            case 'otherincome':
                return <Form.Item>


                </Form.Item>
            case'mortgageProvider':
                return <Form.Item
                    className="mb-0"
                    validateFirst
                    key={question.text}
                    name={question.name}
                    //    label={renderLabel(question, formInstance)}
                    rules={rules}
                    {...restProps}
                >
                    <MortgageProvider  onBlur={specialBlur} ref={props.refToFocus}/>
                </Form.Item>
            case'lender':
                return <Form.Item
                    className="mb-0"
                    validateFirst
                    key={question.text}
                    name={question.name}
                //    label={renderLabel(question, formInstance)}
                    rules={rules}
                    {...restProps}
                >
                    <Lender  onBlur={specialBlur} ref={props.refToFocus}/>
                </Form.Item>
            case'phone':
                return <Form.Item
                    className="mb-0"
                    key={question.text}
                    name={question.name}
                 //   label={renderLabel(question, formInstance)}
                    rules={rules}
                    validateFirst
                    {...restProps}
                >
                    <Phone onBlur={specialBlur} refToFocus={props.refToFocus}
                        precision={0}
                    />
                </Form.Item>
            case 'euro':
                return <Form.Item
                    className="mb-0"
                    key={question.text}
                    name={question.name}
                 //   label={renderLabel(question, formInstance)}
                    //tooltip="Enter the value/price of the property"
                    rules={rules}
                    validateFirst
                    {...restProps}
                >
                    <InputNumber
                        className="mb-0"
                        inputMode="numeric"
                        pattern="[0-9,]*"
                        addonBefore={"€"}
                        precision={0}
                        onBlur={specialBlur} ref={props.refToFocus}
                        formatter={currencyFormatter}
                        parser={currencyParser}
                        style={{width: '100%'}}
                    />
                </Form.Item>
            case 'weight':
                return <Form.Item
                    className="mb-0"
                    key={question.text}
                    name={question.name}
                    //   label={renderLabel(question, formInstance)}
                    //tooltip="Enter the value/price of the property"
                    rules={rules}
                    validateFirst
                    {...restProps}
                >
                    <WeightInput onBlur={specialBlur} name={question.name} />
                </Form.Item>
            case 'height':

                return <Form.Item
                    className="mb-0"
                    key={question.text}
                    name={question.name}
                    //   label={renderLabel(question, formInstance)}
                    //tooltip="Enter the value/price of the property"
                    rules={rules}
                    validateFirst
                    {...restProps}
                >
                    <HeightInput onBlur={specialBlur} name={question.name}/>
                </Form.Item>
            case 'int':
                return <Form.Item
                    className="mb-0"
                    validateFirst
                    key={question.text}
                    name={question.name}
                 //   label={renderLabel(question, formInstance)}
                    rules={rules}
                    {...restProps}
                >
                    <InputNumber
                        onBlur={specialBlur} ref={props.refToFocus}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        precision={0}
                        style={{width: '100%'}}
                    />
                </Form.Item>
            case 'float':
                return <Form.Item
                    className="mb-0"
                    validateFirst
                    key={question.text}
                    name={question.name}
               //     label={renderLabel(question, formInstance)}
                    rules={rules}
                    inputMode="numeric"
                    pattern="[0-9.]*"
                    {...restProps}
                >
                    <InputNumber
                        onBlur={specialBlur} ref={props.refToFocus}
                        precision={2}
                        style={{width: '100%'}}
                    />
                </Form.Item>
            case 'text':
                return <Form.Item
                    className="mb-0"
                    validateFirst
                    key={question.text}
                    name={question.name}
               //     label={renderLabel(question, formInstance)}
                    rules={rules}
                    {...restProps}
                >
                    <Input
                        onBlur={specialBlur} ref={props.refToFocus}
                        style={{width: '100%'}}
                    />
                </Form.Item>
            case 'email':
                return <Form.Item
                    className="mb-0"
                    validateFirst
                    key={question.text}
                    name={question.name}
               //     label={renderLabel(question, formInstance)}
                    rules={rules}
                    {...restProps}
                >
                    <Input type="email"
                           onBlur={specialBlur} ref={props.refToFocus}
                           style={{width: '100%'}}
                    />
                </Form.Item>
            case 'choice':
                return <Form.Item
                    className="mb-0"
                    validateTrigger="onChange"
                    validateFirst
                    key={question.text}
                    name={question.name}
                //    label={renderLabel(question, formInstance)}
                    rules={rules}
                    {...restProps}
                >
                    <Radio.Group style={{display: 'flex', width: '100%'}}>
                        {
                            question.answer.choices.map(item => <Radio.Button
                                style={{flex: 1}} value={item.value} key={`${question.text}${item.value}`} onClick={()=>setTimeout(specialBlur,200)} > {item.text} </Radio.Button>)
                        }
                    </Radio.Group>
                </Form.Item>
            case 'select':
                return <Form.Item
                    className="mb-0"
                    validateFirst
                    key={question.text}
                    name={question.name}
                  //  label={renderLabel(question, formInstance)}
                    rules={rules}
                    {...restProps}
                >
                    <Select  tabIndex="4" style={{width:'100%'}} onBlur={specialBlur} ref={props.refToFocus}>
                        {
                            question.answer.choices.map(item => <Select.Option
                                value={item.value} key={`${question.text}${item.value}`}>{item.text}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
            case 'address':
                return <Form.Item
                    className="mb-0"
                    validateFirst
                    key={question.text}
                    name={question.name}
                //    label={renderLabel(question, formInstance)}
                    rules={rules}
                    {...restProps}
                >
                    <Address onBlur={specialBlur} refToFocus={props.refToFocus}/>
                </Form.Item>
            case 'ddmmyyyy':
                return <Form.Item
                    className="mb-0"
                    validateFirst
                    key={question.text}
                    name={question.name}
                 //   label={renderLabel(question, formInstance)}
                    rules={rules}
                    {...restProps}
                >
                    <DateString onBlur={specialBlur} refToFocus={props.refToFocus}/>
                </Form.Item>
            case 'mmyyyy':
                return <Form.Item
                    className="mb-0"
                    validateFirst
                    key={question.text}
                    name={question.name}
                 //   label={renderLabel(question, formInstance)}
                    rules={rules}
                    {...restProps}
                >
                    <DateString noDays={true} onBlur={specialBlur} refToFocus={props.refToFocus}/>
                </Form.Item>
            case 'country' :
                return <Form.Item
                    className="mb-0"
                    validateFirst
                    key={question.text}
                    name={question.name}
                  //  label={renderLabel(question, formInstance)}
                    rules={rules}
                    {...restProps}
                >
                    <Country onBlur={specialBlur} ref={props.refToFocus}/>
                </Form.Item>
            case 'nationality' :
                return <Form.Item
                    className="mb-0"
                    validateFirst
                    key={question.text}
                    name={question.name}
              //      label={renderLabel(question, formInstance)}
                    rules={rules}
                    {...restProps}
                >
                    <Country nationality={true} onBlur={specialBlur} ref={props.refToFocus}/>
                </Form.Item>
            case 'textarea':
                return <Form.Item
                    className="mb-0"
                    validateFirst
                    key={question.text}
                    name={question.name}
               //     label={renderLabel(question, formInstance)}
                    rules={rules}
                    {...restProps}
                >
                    <TextArea  onBlur={specialBlur} ref={props.refToFocus}/>
                </Form.Item>
            case 'agearray':
                return <Form.Item
                    className="mb-0"
                    validateFirst
                    key={question.text}
                    name={question.name}
                 //  label={renderLabel(question, formInstance)}
                    rules={rules}
                    {...restProps}
                >
                    <DOBArray onBlur={specialBlur} refToFocus={props.refToFocus}/>
                </Form.Item>
            case 'multichoice':
                return <Form.Item
                    validateFirst
                    className="mb-0"
                    key={question.text}
                    name={question.name}
                 //   label={renderLabel(question, formInstance)}
                    rules={rules}
                    {...restProps}
                >
                    <Checkbox.Group style={{display: 'flex', flexDirection: 'column'}}>
                        <Row>
                            {
                                question.answer.choices.map(item => <Col span={12} style={{marginBottom: 3}}
                                                                         key={question.answer.label + item.value}>
                                    <Checkbox size='large' value={item.value}>{item.text}</Checkbox>
                                </Col>)
                            }
                        </Row>
                    </Checkbox.Group>

                </Form.Item>

            default:
                return <div key={question.text}
                            style={{padding: 5, backgroundColor: 'red'}}>{question.answer.type}</div>
        }
    }

    if (!!props.question?.hides || typeof props.question.text === "function" || typeof props.question.label === "function" || typeof props.question.rules === "function") {
        return <Form.Item
            noStyle
            shouldUpdate={true}>
            {(formInstance) => {
                if (props.question?.hides) {
                    if (props.question.hides(formInstance)) {
                        return <></>
                    }
                }
                return renderQuestion(props.question, formInstance)
            }}
        </Form.Item>
    }
    return renderQuestion(props.question)
}

export default FormItemInput;