import React from 'react';
import ReactDOM from 'react-dom/client';
import {Amplify} from 'aws-amplify';
import awsConfig from './aws-exports';
import RoutesIndex from "./sussd/app/RoutesIndex";
const updatedAwsConfig = {
    ...awsConfig,
    oauth: {
        ...awsConfig.oauth,
        domain: "oauth2.sussd.ie",
        redirectSignIn: "https://apply.sussd.ie/register/with-google/",
        redirectSignOut: "https://apply.sussd.ie/login/",
    }
}

Amplify.configure(updatedAwsConfig);
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
        .then((registration) => {
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            // New update available
                            alert('A new version of the app is available. The page will reload now.');
                            window.location.reload();
                        }
                    }
                };
            };
        })
        .catch((error) => {
            console.error('Error during service worker registration:', error);
        });
    });
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RoutesIndex/>);